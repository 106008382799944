import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
//import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const columns = [
  { id: 'primary_name', label: 'Company' },
  { id: 'secondary_name', label: 'Secondary Name' },
  { id: 'onmode_vertical', label: 'Vertical' },
  { id: 'industry', label: 'Industry' },
  { id: 'sector', label: 'Sector' },
  { id: 'website_link', label: 'Website' },
  { id: 'linkedin_link', label: 'LinkedIn' },
  // { id: 'load_date', label: 'Updated' },
  { id: 'actions', label: 'Info' }, // New column for actions
];

const calculateTimeSince = (date) => {
  const now = new Date();
  const diff = now - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};

export default function EntitiesTable({ entityId }) {
  const [row, setRow] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/entities/${entityId}`)
      .then(response => response.json())
      .then(data => setRow(data))
      .catch(error => console.error('Error fetching entities:', error));
  }, [entityId]);

  const handleActionClick = () => {
    // TODO rmove debug, avoidd noise
    console.log('Action clicked');
  };

  const timeSince = row ? calculateTimeSince(row['load_date']) : 0;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
      <h1>Entity: {row && row['primary_name']}</h1>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover role="checkbox" tabIndex={-1}>
              {columns.map((column) => {
                const value = row ? row[column.id] : '';
                return (
                  <TableCell key={column.id} align="left">
                    {column.id === 'actions' ? (
                      <Button variant="contained" color="primary" onClick={handleActionClick}>
                        Action
                      </Button>
                    ) : column.id === 'website_link' && value ? (
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faGlobe} style={{ textAlign: 'center', marginRight: '5px', fontSize: '150%' }} />
                      </a>
                    ) : column.id === 'linkedin_link' && value ? (
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon/>
                        {/* <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px', fontSize: '150%' }} /> */}
                      </a>
                    ) : column.id === 'load_date' ? (
                      <>
                        {value}
                        {timeSince > 10 && (
                          <FontAwesomeIcon icon={faArrowDown} style={{ marginLeft: '5px', color: 'red', fontSize: '150%' }} />
                        )}                              
                      </>
                    ) : (
                      value
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
