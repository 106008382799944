import React, { useState, useEffect } from 'react';

function PortfolioSummary({ portfolioId }) {
  const [portfolioData, setPortfolioData] = useState(null);
  const [editableName, setEditableName] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {

    fetch(`${process.env.REACT_APP_API_URL}/portfolios/${portfolioId}`)
      .then(response => response.json())
      .then(data => {
        setPortfolioData(data);
        setEditableName(data.name); // NOTE set initial editableName to current portfolio name
      })
      .catch(error => console.error('Error fetching portfolio data:', error));
  }, [portfolioId]);

  // const handleEdit = () => {
  //   setIsEditing(true);
  // };

  const handleSave = () => {
    fetch(`${process.env.REACT_APP_API_URL}/portfolios/${portfolioId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: editableName }),
    })
      .then(response => response.json())
      .then(data => {
        setPortfolioData(data); 
        setIsEditing(false);
      })
      .catch(error => console.error('Error updating portfolio:', error));
  };

  const handleChange = (e) => {
    setEditableName(e.target.value); 
  };

  return (
    <div>
      {portfolioData && (
        <div>
          {isEditing ? (
            <div>
              <input
                type="text"
                value={editableName}
                onChange={handleChange}
              />
              <button onClick={handleSave}>Save</button>
            </div>
          ) : (
            <div>
              <h2>Portfolio Name: {portfolioData.name}</h2>
              <p>Description: {portfolioData.description}</p>
              {/* <button onClick={handleEdit}>Edit</button> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default PortfolioSummary;