import React, { useState } from 'react';
import logo from './img/OnMode_ID_Primary_Negative_RGB.png';
import './App.css';
import Login from './Login';
import AppBar from './AppBar';
import EntitiesPage from './EntitiesPage';
import PortfolioPage from './PortfolioPage';
import EntitiesDetailsPage from './EntitiesDetailsPage';
import PortfolioDetailsPage from './PortfolioDetailsPage';
import SummaryPage from './SummaryPage';
import AgentHQPage from './AgentHQPage';
import AgentHQWorkbookPage from './AgentHQWorkbookPage';
import AgentHQTeamSettingsPage from './AgentHQTeamSettingsPage';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { Box } from '@mui/material';
import PermanentDrawer from './PermanentDrawer';

function App() {
  const defaultSearchText = "Ask Onmode...";
  const [searchTerm, setSearchTerm] = useState(defaultSearchText);
  const [searchResult, setSearchResult] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSearchChange = (event) => {
    const userInput = event.target.value;
    if (userInput === defaultSearchText) {
      setSearchTerm('');
    } else {
      setSearchTerm(userInput);
    }
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    console.log(searchTerm);
    
    try {
      if (isLoggedIn) {
        const response = await fetch(`${process.env.REACT_APP_LLMAPI_URL}/api/generate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            model: 'llama2',
            prompt: searchTerm,
            stream: false
          }),
          credentials: 'include',
          mode: 'cors'
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
  
        const data = await response.json();
        console.log('Generating response...');
        setSearchResult(data);
      } else {
        console.log('User is not logged in. Cannot perform search.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
          {isLoggedIn && <AppBar />}
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            {isLoggedIn && <PermanentDrawer />}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Routes>
                <Route path="/" element={
                  <React.Fragment>
                    <Login setIsLoggedIn={setIsLoggedIn} />
                    <div className="container">
                      {isLoggedIn && (
                        <>
                          <img src={logo} className="logo" alt="logo" />
                          <form onSubmit={handleSearchSubmit} className="search-container">
                            <input
                              className="search-input app-search-input"
                              type="text"
                              placeholder={defaultSearchText}
                              value={searchTerm}
                              onChange={handleSearchChange}
                            />
                            <button type="submit" className="search-button app-search-button">Generate</button>
                          </form>
                          <p>Query: {searchTerm}</p>
                          {searchResult && (
                            <div>
                              <h2>Generated Result:</h2>
                              <pre>{JSON.stringify(searchResult, null, 2)}</pre>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </React.Fragment>
                } />
                <Route path="/entities" element={<EntitiesPage />} />
                <Route path="/summary" element={<SummaryPage />} />
                <Route path="/agenthq" element={<AgentHQPage />} />
                <Route path="/agenthqworkbook" element={<AgentHQWorkbookPage />} />

                <Route path="/team-settings" element={<AgentHQTeamSettingsPage />} />
                <Route path="/portfolios" element={<PortfolioPage />} />
                <Route path="/portfolios/:portfolio_id" element={<PortfolioDetailsPage />} />
                <Route path="/entities/:id" element={<EntitiesDetailsPage />} />

              </Routes>
            </Box>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
