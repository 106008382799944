// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Login.css */
.login-container {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  
  .login-button {
    background-color: #184c90;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0d47a1;
  }
  
  .logout-container {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  
  .logout-button {
    background-color: #184c90;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #0d47a1;
  }
   `, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Login.css */\n.login-container {\n    position: absolute;\n    top: 10px;\n    right: 20px;\n  }\n  \n  .login-button {\n    background-color: #184c90;\n    color: #fff;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .login-button:hover {\n    background-color: #0d47a1;\n  }\n  \n  .logout-container {\n    position: absolute;\n    top: 10px;\n    right: 20px;\n  }\n  \n  .logout-button {\n    background-color: #184c90;\n    color: #fff;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .logout-button:hover {\n    background-color: #0d47a1;\n  }\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
