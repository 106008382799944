import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#424242',
  color: 'white',
  margin: theme.spacing(2),
}));

const PortfolioValueCard = () => {
  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h6" component="div" sx={{ fontSize: 12 }}>
          Portfolio Overview
        </Typography>
        <Typography variant="h4" component="div" sx={{ fontSize: 32, marginTop: 1, fontWeight: 'bold' }}>
          £130.4 M
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default PortfolioValueCard;
