import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './App.css';
import Login from './Login';
// import AppBar from './AppBar';
import EntitiesTable from './EntitiesTable';
import PortfolioSummary from './PortfolioSummary';

function PortfolioDetailsPage() {
  const { portfolio_id } = useParams();

  const [isLoggedIn, setIsLoggedIn] = useState(false); 

  // TODO Remove debug, avoid noise!
  console.log('Details:', portfolio_id)

  return (
    <React.Fragment>
      <Login setIsLoggedIn={setIsLoggedIn} />
      {isLoggedIn}
      <div className="tables-container">
        {isLoggedIn && (
          <>
            <PortfolioSummary portfolioId={parseInt(portfolio_id)} />
            <EntitiesTable portfolio_id={parseInt(portfolio_id)} />
          </>
        )}
      </div>
    </React.Fragment>
    )
}

export default PortfolioDetailsPage;
