import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DescriptionIcon from '@mui/icons-material/Description';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PortfolioValueCard from './PortfolioValueCard';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const drawerWidth = 240;
const collapsedWidth = 80;

const StyledListItem = styled(ListItem)(({ theme, open }) => ({
  backgroundColor: open ? 'black' : 'inherit',
  borderRadius: '20px',
  margin: open ? theme.spacing(1, 0) : theme.spacing(0),
  padding: open ? theme.spacing(1, 3) : theme.spacing(1),
  '&:hover': {
    backgroundColor: open ? '#424242' : 'inherit',
  },
  justifyContent: open ? 'initial' : 'center',
  alignItems: 'center',
}));

const PermanentDrawer = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      sx={{
        width: open ? drawerWidth : collapsedWidth,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : collapsedWidth,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List sx={{ marginTop: '50px' }}>
        <StyledListItem button onClick={() => handleNavigation('/summary')} open={open}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          {open && <ListItemText primary="Home" />}
        </StyledListItem>
        <StyledListItem button onClick={() => handleNavigation('/entities')} open={open}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          {open && <ListItemText primary="Companies" />}
        </StyledListItem>
        <StyledListItem button onClick={() => handleNavigation('/portfolios')} open={open}>
          <ListItemIcon>
            <ChecklistIcon />
          </ListItemIcon>
          {open && <ListItemText primary="Portfolios" />}
        </StyledListItem>
        <StyledListItem button onClick={() => handleNavigation('/')} open={open}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          {open && <ListItemText primary="Reports" />}
        </StyledListItem>
        <StyledListItem button onClick={() => handleNavigation('/')} open={open}>
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          {open && <ListItemText primary="Data Library" />}
        </StyledListItem>
        <StyledListItem button onClick={() => handleNavigation('/')} open={open}>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          {open && <ListItemText primary="Alerts" />}
        </StyledListItem>
        <StyledListItem button onClick={() => handleNavigation('/')} open={open}>
          <ListItemIcon>
            <AutoGraphIcon />
          </ListItemIcon>
          {open && <ListItemText primary="Analytics" />}
        </StyledListItem>
        <StyledListItem button onClick={() => handleNavigation('/agenthqworkbook')} open={open}>
          <ListItemIcon>
            <SmartToyIcon />
          </ListItemIcon>
          {open && <ListItemText primary="Agent HQ" />}
        </StyledListItem>
        <StyledListItem button onClick={() => handleNavigation('/agenthq')} open={open}>
          <ListItemIcon>
            <SmartToyIcon />
          </ListItemIcon>
          {open && <ListItemText primary="Agent HQ Teams" />}
        </StyledListItem>        
      </List>
      <Divider />
      <PortfolioValueCard open={open} />
      <IconButton onClick={toggleDrawer} sx={{ alignSelf: 'center', marginBottom: 1 }}>
        {open ? <ArrowCircleLeftIcon /> : <ArrowCircleRightIcon />}
      </IconButton>
    </Drawer>
  );
};

export default PermanentDrawer;
