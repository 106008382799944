import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './App.css';
import Login from './Login';
// import AppBar from './AppBar';
// import EntitiesTable from './EntitiesTable';
import EntitiesDetailsTable from './EntitiesDetailsTable';
import MaterialsTable from './MaterialsTable';
import MeasuresTable from './MeasuresTable';

function EntitiesDetailsPage() {
  const { id } = useParams();

  const [isLoggedIn, setIsLoggedIn] = useState(false); 

  return (
    <React.Fragment>
      <Login setIsLoggedIn={setIsLoggedIn} />
      {isLoggedIn}
      <div className="tables-container">
        {isLoggedIn && (
          <>
            <EntitiesDetailsTable entityId={parseInt(id)} />
            <MeasuresTable entityId={parseInt(id)} />
            <MaterialsTable entityId={parseInt(id)} />
          </>
        )}
      </div>
    </React.Fragment>
    )
}

export default EntitiesDetailsPage;
