import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Login.css';
//import logo from './OnMode_ID_Primary_Positive_RGB.png';

const Login = ({ setIsLoggedIn }) => {
//const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
const { loginWithRedirect, logout, isAuthenticated } = useAuth0();


  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    setIsLoggedIn(false);
  };

  if (isAuthenticated) {
    setIsLoggedIn(true);
  }

  return (
      
    <div className="login-container">
      {!isAuthenticated && (
        <button className="login-button" onClick={() => loginWithRedirect()}>
          Log In
        </button>
      )}
      {isAuthenticated && (
        <div>
          {/* <p>{user.email}</p> */}
          {/* <img src={user.picture} alt="Profile" /> */}
          <button className="logout-button" onClick={handleLogout}>
            Log Out
          </button>
        </div>
      )}

    </div>
  );
};

export default Login;