import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Card, CardContent } from '@mui/material';

// TODO Update dummy example with real stuff
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const generateRandomData = () => {
  const labels = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  const data = labels.map(() => Math.floor(Math.random() * (500000 - 1000 + 1)) + 1000);
  
  return {
    labels,
    datasets: [
      {
        label: 'US Dollars',
        backgroundColor: 'rgba(255,255,255,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 1,
        data,
      },
    ],
  };
};

const BarChartComponent = () => {
  const data = generateRandomData();

  return (
    <Card className="chart-card" sx={{ backgroundColor: 'black', borderRadius: '20px' }}>
      <CardContent>
        <Bar
          data={data}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'Last 12 Months Portfolio Revenue',
                fontSize: 20,
                fontColor: 'white',
              },
              legend: {
                display: true,
                position: 'right',
                labels: {
                  color: 'white', 
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white',
                },
              },
              y: {
                ticks: {
                  color: 'white',
                },
              },
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default BarChartComponent;
