import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
// import Card from './Card';
import './App.css';

const columns = [
  { id: 'select', label: 'Select', disableSorting: true },
  { id: 'primary_name', label: 'Company' },
//  { id: 'secondary_name', label: 'Secondary Name' },
  { id: 'onmode_vertical', label: 'Vertical' },
 { id: 'industry', label: 'Industry' },
 { id: 'sector', label: 'Sector' },
  { id: 'website_link', label: 'Website' },
  { id: 'linkedin_link', label: 'LinkedIn' },
//  { id: 'load_date', label: 'Updated' },
  { id: 'actions', label: 'Info' },
];

// TODO Fix
const calculateTimeSince = (date) => {
  const now = new Date();
  const diff = now - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};

export default function EntitiesTable({ portfolio_id }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/entities`;
    if (portfolio_id) {
      url = `${process.env.REACT_APP_API_URL}/portfolios/${portfolio_id}/entities`;
    }

    fetch(url)
      .then(response => response.json())
      .then(data => setRows(data))
      .catch(error => console.error('Error fetching entities:', error));
  }, [portfolio_id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilterChange = (event, columnId) => {
    const { value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [columnId]: value,
    }));
  };

  const filteredRows = rows.filter(row => {
    return Object.entries(filters).every(([columnId, filterValue]) => {
      if (!filterValue) return true;
      const cellValue = row[columnId].toString().toLowerCase();
      return cellValue.includes(filterValue.toLowerCase());
    });
  });

  const handleActionClick = (row) => {
    navigate(`/entities/${row.entity_id}`);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row) => row.entity_id);
      setSelectedRows(newSelecteds);
      return;
    }
    setSelectedRows([]);
  };

  const handleCheckboxClick = (event, entityId) => {
    const selectedIndex = selectedRows.indexOf(entityId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, entityId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const isSelected = (entityId) => selectedRows.indexOf(entityId) !== -1;

  return (
    <div className="entities-table-wrapper">
      <Paper className="entities-table-container" elevation={3}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="table-head">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    className="table-cell"
                    style={{ fontWeight: 'bold' }}
                  >
                    {column.label}
                    {column.id === 'select' && (
                      <Checkbox
                        indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
                        checked={rows.length > 0 && selectedRows.length === rows.length}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all entities' }}
                        style={{ color: 'white' }}
                      />
                    )}
                    {column.id !== 'select' && (
                      <>
                        <br />
                        <input
                          type="text"
                          placeholder="Filter"
                          value={filters[column.id] || ''}
                          onChange={(event) => handleFilterChange(event, column.id)}
                          className="table-filter-input"
                        />
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.entity_id);
                  const timeSince = calculateTimeSince(row['load_date']);
                  const rowClass = index % 2 === 0 ? 'table-row' : '';
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.entity_id}
                      selected={isItemSelected}
                      className={rowClass}
                      style={{ color: 'white', backgroundColor: '#424242' }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === 'select') {
                          return (
                            <TableCell padding="checkbox" key={column.id} className="table-cell">
                              <Checkbox
                                checked={isItemSelected}
                                onClick={(event) => handleCheckboxClick(event, row.entity_id)}
                                inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${row.entity_id}` }}
                                style={{ color: 'white' }}
                              />
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={column.id} align="left" className="table-cell">
                            {column.id === 'actions' ? (
                              <Button variant="contained" color="primary" onClick={() => handleActionClick(row)}>
                                Details
                              </Button>
                            ) : column.id === 'website_link' && value ? (
                              <a href={value} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faGlobe} style={{ textAlign: 'center', marginRight: '5px', fontSize: '150%', color: "white" }} />
                              </a>
                            ) : column.id === 'linkedin_link' && value ? (
                              <a href={value} target="_blank" rel="noopener noreferrer">
                                <LinkedInIcon style={{ color: 'white' }} />
                                <TwitterIcon style={{ color: 'white' }} />
                                <FacebookIcon style={{ color: 'white' }} />
                              </a>
                            ) : column.id === 'load_date' ? (
                              <>
                                {value}
                                {timeSince > 10 && (
                                  <FontAwesomeIcon icon={faArrowDown} style={{ marginLeft: '5px', color: 'red', fontSize: '150%' }} />
                                )}
                              </>
                            ) : column.id === 'description' && value.length > 100 ? (
                              `${value.substring(0, 100)}...`
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="pagination"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
