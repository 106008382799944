import React, { useState } from 'react';
import './App.css';
import Login from './Login';
//import AppBar from './AppBar';
import PortfolioSummaryChart from './PortfolioSummaryChart';
import { Card, CardContent, Button, IconButton } from '@mui/material'; // TODO Add Typography if we need it
import MenuIcon from '@mui/icons-material/Menu';

function SummaryPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <React.Fragment>
      <Login setIsLoggedIn={setIsLoggedIn} />
      {isLoggedIn && (
        <div className="tables-container">
          {/* <Typography variant="h1" sx={{ color: 'white', fontSize: 40, marginBottom: '10px', fontWeight: 'bold', textAlign: 'left' }}>
            Overview
          </Typography> */}
          <div className="summary-grid">
            {Array.from({ length: 3 }).map((_, colIndex) => (
              <div className="column" key={colIndex}>
                {Array.from({ length: 3 }).map((_, cardIndex) => (
                  <Card className="summary-card" key={cardIndex} sx={{ backgroundColor: 'black', borderRadius: '20px' }}>
                    <CardContent>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color="inherit" aria-label="menu" sx={{ marginRight: '10px' }}>
                          <MenuIcon />
                        </IconButton>
                        <p style={{ fontWeight: 'bold', fontSize: '14px' }}>Portfolio Details</p>
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{
                            marginLeft: 'auto',
                            borderColor: '#424242',
                            marginTop: '10px',
                            color: '#424242',
                            backgroundColor: '#f5f5f5', 
                            '&:hover': {
                              backgroundColor: '#e0e0e0',
                            },
                          }}
                        >
                          View Portfolio
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            ))}
          </div>
          <div className="summary-chart-container" style={{ width: '30%' }}>
            <PortfolioSummaryChart className="summary-chart" />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default SummaryPage;
