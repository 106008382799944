import React, { useState } from 'react';
import './App.css';
import Login from './Login';
//import AppBar from './AppBar';
import EntitiesTable from './EntitiesTable';
// import EntitiesDetailsTable from './EntitiesDetailsTable';
// import MaterialsTable from './MaterialsTable';
// import MeasuresTable from './MeasuresTable';

function EntitiesPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); 

  return (
    <React.Fragment>
      <Login setIsLoggedIn={setIsLoggedIn} />
      {isLoggedIn}
      <div className="tables-container">
        {isLoggedIn && (
          <>
            <EntitiesTable/>
          </>
        )}
      </div>
    </React.Fragment>
    )
}

export default EntitiesPage;
