import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
//import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faArrowDown, faBuilding } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';

const columns = [
  { id: 'measure_type', label: 'Type' },
  { id: 'measure_subtype', label: 'Subtype' },
  { id: 'measure_source', label: 'Source' },
  { id: 'measure_date', label: 'Measure Date' },
  { id: 'measure_value', label: 'Value' },
  // { id: 'actions', label: 'Info' },
];

// SELECT EM.MEASURE_ID, EM.MEASURE_TYPE, EM.MEASURE_SUBTYPE, EM.MEASURE_SOURCE, EM.MEASURE_DATE, EM.MEASURE_VALUE
// FROM ENTITY_MEASURES EM
// WHERE ENTITY_ID = 1;


// {
//     "entity_id": 6,
//     "load_date": "Mon, 04 Dec 2023 16:38:32 GMT",
//     "measure_date": "NA",
//     "measure_description": "High",
//     "measure_id": 11,
//     "measure_link": "https://www.csrhub.com/CSR_and_sustainability_information/Hiscox-Limited",
//     "measure_source": "CSRHUB",
//     "measure_subtype": "ESG Ranking",
//     "measure_type": "ESG Rating",
//     "measure_value": "85%"
//   }


const calculateTimeSince = (date) => {
  const now = new Date();
  const diff = now - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};

export default function MeasuresTable({ entityId }) {
  const [row, setRow] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/entity_measures/${entityId}`)
      .then(response => response.json())
      .then(data => setRow(data))
      .catch(error => console.error('Error fetching entities:', error));
  }, [entityId]);

  const handleActionClick = () => {
    console.log('Action clicked');
  };

  const timeSince = row ? calculateTimeSince(row['load_date']) : 0;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
      <h2>Measures</h2>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover role="checkbox" tabIndex={-1}>
              {columns.map((column) => {
                const value = row ? row[column.id] : '';
                return (
                  <TableCell key={column.id} align="left">
                    {column.id === 'actions' ? (
                      <Button variant="contained" color="primary" onClick={handleActionClick}>
                        Action
                      </Button>
                    ) : column.id === 'website_link' && value ? (
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faGlobe} style={{ textAlign: 'center', marginRight: '5px', fontSize: '150%' }} />
                      </a>
                    ) : column.id === 'linkedin_link' && value ? (
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px', fontSize: '150%' }} />
                      </a>
                    ) : column.id === 'load_date' ? (
                      <>
                        {value}
                        {timeSince > 10 && (
                          <FontAwesomeIcon icon={faArrowDown} style={{ marginLeft: '5px', color: 'red', fontSize: '150%' }} />
                        )}                              
                      </>
                    ) : (
                      value
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

