import React, { useState } from 'react';
import './App.css';
import Login from './Login';
import { Typography, Divider, Box } from '@mui/material';
import AgentHQCard from './AgentHQCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function AgentHQPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <React.Fragment>
      <Login setIsLoggedIn={setIsLoggedIn} />
      {isLoggedIn && (
        <div className="tables-container">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: 'white',
              marginBottom: '10px',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: 26,
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              Agent HQ Teams
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: 10,
                  marginRight: '5px',
                }}
              >
                Included in Plan
              </Typography>
              {true ? (
                <CheckCircleIcon sx={{ color: 'green' }} />
              ) : (
                <CancelIcon sx={{ color: 'red' }} />
              )}
            </Box>
          </Box>
          <Divider sx={{ backgroundColor: 'white', marginBottom: '10px' }} />

          <div className="summary-grid">
          <AgentHQCard 
            title="Data Hunting Team" 
            description1="Identifies new high value sources of data suitable for integration and insight generation." 
            description2="AgentHQ enforces controlled access to a range of sources, which include Onmode Database, Client Data and Web" 
            enabled={true} 
          />
          <AgentHQCard 
            title="QA/QC Team" 
            description1="Conducts quality assessment and control of data before it is deemed suitable for integration or analysis" 
            description2="Pre-trained agents using Onmode’s quality assessment and control rules, with opportunity to extend using Client defined rules" 
            enabled={false} 
          />
          <AgentHQCard 
            title="Data Curation Team" 
            description1="Conducts a range of data curation activities, focused on extracting more greater value from data assets" 
            description2="Onmode’s curation guidelines offer a broad range of curation capabilities, that can be extended using Client defined guidelines" 
            enabled={true} 
          />
          <AgentHQCard 
            title="Data Science Team" 
            description1="Conducts a broad range of analytical tasks that can be used in the generation of new domain relevant data insights" 
            description2="Pre-trained agents capable of conducting a range of data science tasks and analysis activities" 
            enabled={false} 
          />
          <AgentHQCard 
            title="Domain Analyst Team" 
            description1="Offers expert guidance within a specific domain area (e.g. finance, healthcare, energy)." 
            description2="Pre-trained agents capable of assessing data, answering questions, and setting up alerts relevant to a specific domain area" 
            enabled={true} 
          />
          <AgentHQCard 
            title="Report Writing Team" 
            description1="Writes detailed reports, suitable for strategic planning and information dissemination" 
            description2="Onmode offers a range of domain-specific report templates, that can be extended using Client report structure definitions" 
            enabled={true} 
          />
          <AgentHQCard 
            title="Live Demo Team" 
            description1="Something" 
            description2="Something" 
            enabled={false} 
          />
        </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default AgentHQPage;
