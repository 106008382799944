import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './Login';
import { Typography, Divider, Box, CircularProgress } from '@mui/material';
import ChatOverlay from './ChatOverlay';
import PortfolioTable from './PortfolioTable'; 
import EntitiesTable from './EntitiesTable';

function AgentHQWorkbookPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [apiResponses, setApiResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [showPortfolioDetails, setShowPortfolioDetails] = useState(null);

  useEffect(() => {
    if (showPortfolio) {
      const newResponse = {
        type: 'portfolio',
        component: (
          <>
            <PortfolioTable />
            <Box className="api-response-timestamp" sx={{ fontSize: '0.75rem', color: 'gray', textAlign: 'right' }}>
              {new Date().toLocaleTimeString()}
            </Box>
          </>
        ),
        timestamp: new Date().toLocaleTimeString(),
      };
      setApiResponses((prevResponses) => [...prevResponses, newResponse]);
    }
  }, [showPortfolio]);

  useEffect(() => {
    if (showPortfolioDetails) {
      const newResponse = {
        type: 'entities',
        component: (
          <>
            <EntitiesTable portfolio_id={showPortfolioDetails} />
            <Box className="api-response-timestamp" sx={{ fontSize: '0.75rem', color: 'gray', textAlign: 'right' }}>
              {new Date().toLocaleTimeString()}
            </Box>
          </>
        ),
        timestamp: new Date().toLocaleTimeString(),
      };
      setApiResponses((prevResponses) => [...prevResponses, newResponse]);
    }
  }, [showPortfolioDetails]);

  return (
    <React.Fragment>
      <Login setIsLoggedIn={setIsLoggedIn} />
      {isLoggedIn && (
        <div className="tables-container" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: 'white',
              marginBottom: '10px',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: 26,
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              Welcome to Agent HQ
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {/* TODO more stuff here when we're ready */}
            </Box>
          </Box>

          <Divider sx={{ backgroundColor: 'white', marginBottom: '10px' }} />

          <Box sx={{ display: 'flex', gap: '20px', flexGrow: 1, height: '100%' }}>
            <Box
              sx={{
                width: '25%',
                backgroundColor: '#f5f5f5',
                borderRadius: '10px',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                height: '100%',
                position: 'relative',
              }}
            >
              <ChatOverlay
                setApiResponses={setApiResponses}
                setLoading={setLoading}
                setShowPortfolio={setShowPortfolio}
                setShowPortfolioDetails={setShowPortfolioDetails}
              />
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                  <CircularProgress color="inherit" size={24} />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                width: '75%',
                backgroundColor: '#424242',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                height: '100%',
                overflowY: 'auto',
                color: 'white',
              }}
            >
              {apiResponses.map((response, index) => (
                <Box
                  key={index}
                  className="api-response"
                  sx={{ backgroundColor: '#303030', borderRadius: '10px', margin: '5px 0', padding: '10px', color: 'white' }}
                >
                  {response.component ? (
                    response.component
                  ) : (
                    <>
                      <Box className="api-response-text">{response.text}</Box>
                      <Box className="api-response-timestamp" sx={{ fontSize: '0.75rem', color: 'gray', textAlign: 'right' }}>
                        {response.timestamp}
                      </Box>
                    </>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </div>
      )}
    </React.Fragment>
  );
}

export default AgentHQWorkbookPage;
