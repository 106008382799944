import React, { useState } from 'react';
import './App.css';
import Login from './Login';
// import AppBar from './AppBar';
import PortfolioTable from './PortfolioTable';

function PortfolioPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); 

  return (
    <React.Fragment>
      <Login setIsLoggedIn={setIsLoggedIn} />
      {isLoggedIn}
      <div className="tables-container">
        {isLoggedIn && (
          <div className="tables-container">
            {/* <ButtonRow /> */}
            <PortfolioTable />
            {/* <PortfolioForm /> */}

          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default PortfolioPage;
