import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';

const columns = [
  { id: 'entity_id', label: 'ID'},
  { id: 'title', label: 'Title' },
  { id: 'material_type', label: 'Type' },
  { id: 'material_subtype', label: 'Subtype' },
  { id: 'publication_date', label: 'Publication Date' },
  { id: 'material_link', label: 'Link' },
  { id: 'embedding_generated', label: 'Ebbedding' },
  // { id: 'actions', label: 'Info' }
];

// SELECT EM.MATERIAL_SUBTYPE,  EM.PUBLICATION_DATE,  EM.TITLE, EM.MATERIAL_LINK, EM.EMBEDDING_GENERATED as ONMODE_PROCESSED
// FROM ENTITY_MATERIALS EM
// WHERE ENTITY_ID = 1
// AND EM.MATERIAL_TYPE = 'Report';


// {
//   "download": true,
//   "download_error": false,
//   "embedding_generated": true,
//   "entity_id": 6,
//   "esg_related": "NA",
//   "filename": "9544e2671d561b36eb53dc2ad8f30e10.html",
//   "load_date": "Mon, 04 Dec 2023 22:18:24 GMT",
//   "material_id": 542,
//   "material_link": "https://www.insuranceinsider.com/article/2c080qb8ojcsgp998d6v4/london-market-section/hiscox-writes-first-risk-within-sub-syndicate-esg-3033",
//   "material_subtype": null,
//   "material_type": "News",
//   "paywall": "NA",
//   "publication_date": "02/08/2023",
//   "search_strategy": "Google News Auto Search",
//   "title": "Hiscox writes first risk within sub-syndicate ESG 3033 - The Insurance Insider"
// },


// const calculateTimeSince = (date) => {
//   const now = new Date();
//   const diff = now - new Date(date);
//   const days = Math.floor(diff / (1000 * 60 * 60 * 24));
//   return days;
// };

export default function MaterialsTable({ entityId }) {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/entity_materials`)
      .then(response => response.json())
      .then(data => {

        const filteredRows = data.filter(row => row.entity_id === entityId);
        setRows(filteredRows);
      })
      .catch(error => console.error('Error fetching entity materials:', error));
  }, [entityId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilterChange = (event, columnId) => {
    const { value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [columnId]: value,
    }));
  };

  const filteredRows = rows.filter(row => {
    return Object.entries(filters).every(([columnId, filterValue]) => {
      if (!filterValue) return true;
      const cellValue = row[columnId].toString().toLowerCase();
      return cellValue.includes(filterValue.toLowerCase());
    });
  });

  const handleActionClick = (row) => {
    // TODO Clenup debug
    console.log('Action clicked for row:', row);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
      <h2>Materials</h2>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ fontWeight: 'bold' }}
                >
                  {column.label}
                  <br />
                  <input
                    type="text"
                    placeholder="Filter"
                    value={filters[column.id] || ''}
                    onChange={(event) => handleFilterChange(event, column.id)}
                    style={{ width: '80%', marginTop: '5px' }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === 'entity_id') {
                          return null; // Don't render cell with entity_id
                        }
                        return (
                          <TableCell key={column.id} align="left">
                            {column.id === 'actions' ? (
                              <Button variant="contained" color="primary" onClick={() => handleActionClick(row)}>
                                Action
                              </Button>
                            ) : column.id === 'material_link' && value ? (
                              <a href={value} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faGlobe} style={{ textAlign: 'center', marginRight: '5px', fontSize: '150%' }} />
                              </a>
                            ) : column.id === 'embedding_generated' && value ? (
                              <a href={value} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon="fas fa-check-circle" style={{color: "#63E6BE",}} />                              </a>
                            ) : column.id === 'description' && value.length > 100 ? (
                              `${value.substring(0, 100)}...`
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
