import React, { useState } from 'react';
import './App.css';
import Login from './Login';
import { Typography, Divider, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function AgentHQTeamSettingsPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const { title, description1, description2 } = location.state || {};

  const includedInPlan = true;  // TODO - make this DB / API call later

  return (
    <React.Fragment>
      <Login setIsLoggedIn={setIsLoggedIn} />
      {isLoggedIn && (
        <div className="tables-container">
          <Typography
            variant="h1"
            sx={{
              color: 'white',
              fontSize: 26,
              marginBottom: '10px',
              fontWeight: 'bold',
              textAlign: 'left',
            }}
          >
            {title} Profile
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              color: 'white',
              marginBottom: '10px',
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: 16,
                marginRight: '5px',
              }}
            >
              Included in Plan
            </Typography>
            {includedInPlan ? (
              <CheckCircleIcon sx={{ color: 'green' }} />
            ) : (
              <CancelIcon sx={{ color: 'red' }} />
            )}
          </Box>
          <Divider sx={{ backgroundColor: 'white', marginBottom: '10px' }} />
          <Typography
            variant="h3"
            sx={{
              color: 'white',
              fontSize: 16,
              marginBottom: '10px',
              textAlign: 'left',
            }}
          >
            {description1}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              color: 'white',
              fontSize: 16,
              textAlign: 'left',
              fontStyle: 'italic',
            }}
          >
            {description2}
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
}

export default AgentHQTeamSettingsPage;
