import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import './App.css';

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'description', label: 'Description' },
  { id: 'load_date', label: 'Loaded' },
  { id: 'entity_count', label: 'Entities Count' },
  { id: 'alerts', label: 'Alerts' },
  { id: 'actions', label: 'Action' },
];

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

// const calculateTimeSince = (date) => {
//   const now = new Date();
//   const diff = now - new Date(date);
//   const days = Math.floor(diff / (1000 * 60 * 60 * 24));
//   return days;
// };
 
const PortfolioTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [entitiesCount, setEntitiesCount] = useState({});
  const navigate = useNavigate();
  const [editableRow, setEditableRow] = useState(null);
  const [newRow, setNewRow] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/portfolios`)
      .then(response => response.json())
      .then(data => setRows(data))
      .catch(error => console.error('Error fetching portfolios:', error));
  }, []);

  useEffect(() => {
    const fetchPortfolioEntitiesCount = async () => {
      const entitiesCountData = await Promise.all(
        rows.map(async portfolio => {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/portfolios/${portfolio.portfolio_id}/entities`);
          const data = await response.json();
          return { portfolio_id: portfolio.portfolio_id, entities_count: data.length };
        })
      );
      const countMap = {};
      entitiesCountData.forEach(item => {
        countMap[item.portfolio_id] = item.entities_count;
      });
      setEntitiesCount(countMap);
    };

    fetchPortfolioEntitiesCount();
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = (row) => {
    setEditableRow(row);
  };

  const handleSaveClick = () => {
    fetch(`${process.env.REACT_APP_API_URL}/portfolios/${editableRow.portfolio_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: editableRow.name,
        description: editableRow.description,
      }),
    })
      .then(response => response.json())
      .then(data => {
        const updatedRows = rows.map(row => (row.portfolio_id === data.portfolio_id ? data : row));
        setRows(updatedRows);
        setEditableRow(null); 
      })
      .catch(error => console.error('Error updating portfolio:', error));
  };

  const handleCancelClick = () => {
    setEditableRow(null); 
  };

  const handleActionClick = (row) => {
    navigate(`/portfolios/${row.portfolio_id}`);
  };

  const handleAddClick = () => {
    setNewRow({ name: '', description: '' });
  };

  const handleNewRowChange = (field, value) => {
    setNewRow(prevNewRow => ({
      ...prevNewRow,
      [field]: value,
    }));
  };

  const handleSaveNewRow = () => {
    const newRowWithUserId = {
      ...newRow,
      user_id: 1,
    };

    fetch(`${process.env.REACT_APP_API_URL}/portfolios`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newRowWithUserId),
    })
      .then(response => response.json())
      .then(data => {
        setRows([...rows, data]);
        setNewRow(null);
      })
      .catch(error => console.error('Error creating portfolio:', error));
  };

  const handleDeleteClick = (row) => {
    fetch(`${process.env.REACT_APP_API_URL}/portfolios/${row.portfolio_id}`, {
      method: 'DELETE',
    })
      .then(() => {
        const updatedRows = rows.filter(r => r.portfolio_id !== row.portfolio_id);
        setRows(updatedRows);
      })
      .catch(error => console.error('Error deleting portfolio:', error));
  };

  return (
    <Paper className="portfolio-table-container" elevation={3}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" className="portfolio-table">
          <TableHead className="portfolio-table-head">
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  className="portfolio-table-cell-head"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="portfolio-table-body">
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                //const timeSince = calculateTimeSince(row['load_date']);
                const isEditing = editableRow && editableRow.portfolio_id === row.portfolio_id;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index} className="portfolio-table-row">
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align="left" className="portfolio-table-cell">
                          {isEditing && (column.id === 'name' || column.id === 'description') ? (
                            <input
                              type="text"
                              value={editableRow[column.id]}
                              onChange={(e) =>
                                setEditableRow({
                                  ...editableRow,
                                  [column.id]: e.target.value,
                                })
                              }
                              className="portfolio-table-input"
                            />
                          ) : (
                            <div>{column.id === 'entity_count' ? entitiesCount[row.portfolio_id] : value}</div>
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell className="portfolio-table-cell">
                      {isEditing ? (
                        <>
                          <StyledButton onClick={handleSaveClick}>Save</StyledButton>
                          <Button onClick={handleCancelClick}>Cancel</Button>
                        </>
                      ) : (
                        <>
                          <StyledButton onClick={() => handleEditClick(row)}>Edit</StyledButton>
                          <StyledButton onClick={() => handleActionClick(row)}>Details</StyledButton>
                          <DeleteButton onClick={() => handleDeleteClick(row)}>Delete</DeleteButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {newRow && (
              <TableRow className="portfolio-table-row">
                {columns.map((column) => (
                  <TableCell key={column.id} className="portfolio-table-cell">
                    {column.id === 'name' || column.id === 'description' ? (
                      <input
                        type="text"
                        value={newRow[column.id]}
                        onChange={(e) => handleNewRowChange(column.id, e.target.value)}
                        className="portfolio-table-input"
                      />
                    ) : null}
                  </TableCell>
                ))}
                <TableCell className="portfolio-table-cell">
                  <StyledButton onClick={handleSaveNewRow}>Save</StyledButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="portfolio-table-pagination"
      />
      <Button onClick={handleAddClick} variant="contained" color="primary" className="portfolio-table-add-button">Add</Button>
    </Paper>
  );
};

export default PortfolioTable;
