import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';  // TODOO Find better option
import { useNavigate } from 'react-router-dom';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#424242', // TODO standardise theme for dark gray background
  color: 'white',
  margin: theme.spacing(2),
  borderRadius: '20px',
  position: 'relative',
}));

const StatusIcon = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const AgentHQCard = ({ title, description1, description2, enabled }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/team-settings', {
      state: { title, description1, description2 },
    });
  };

  return (
    <StyledCard onClick={handleClick}>
      <StatusIcon>
        {enabled ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}
      </StatusIcon>
      <CardContent>
        <Typography variant="h2" component="div" sx={{ fontSize: 22, fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="h3" component="div" sx={{ fontSize: 16, marginTop: 1 }}>
          {description1}
        </Typography>
        <Typography variant="h3" component="div" sx={{ fontSize: 16, marginTop: 1, fontWeight: 'italic' }}>
          {description2}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default AgentHQCard;
