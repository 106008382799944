import React, { useState } from 'react';
import axios from 'axios';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import './App.css';
import { Typography, Box, CircularProgress } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const ChatOverlay = ({ setApiResponses, setShowPortfolio, setShowPortfolioDetails }) => {
  const [inputValue, setInputValue] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (inputValue.trim() !== '') {
      const newMessage = {
        text: inputValue,
        timestamp: new Date().toLocaleTimeString(),
      };
      setChatHistory([...chatHistory, newMessage]);
      setInputValue('');

      const showPortfolioByIdMatch = inputValue.toLowerCase().match(/^show my portfolio by id (\d+)$/);
      if (showPortfolioByIdMatch) {
        const portfolioId = showPortfolioByIdMatch[1];
        setShowPortfolio(false);
        setShowPortfolioDetails(portfolioId);
        setApiResponses([]);
        setLoading(false);
      } else if (inputValue.toLowerCase() === 'show my portfolios') {
        setShowPortfolio(true);
        setShowPortfolioDetails(null); 
        setApiResponses([]);
        setLoading(false);
      } else {
        setShowPortfolio(false);
        setShowPortfolioDetails(null);
        setLoading(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_LLMAPI_URL}/api/generate`, {
            model: 'llama3.1',
            prompt: inputValue,
            stream: false
          }, {
            withCredentials: true,
            // headers: {
            //   'Content-Type': 'application/json'
            // }
          });
          
          const { data } = response;
          setApiResponses(prevResponses => [
            ...prevResponses,
            { text: data.response, timestamp: new Date().toLocaleTimeString() },
          ]);
        } catch (error) {
          console.error('Error calling the API:', error);
          setApiResponses(prevResponses => [
            ...prevResponses,
            { text: 'Error getting response from API.', timestamp: new Date().toLocaleTimeString() },
          ]);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <div className="chat-overlay">
      <Box className="chat-header" sx={{ flexShrink: 0 }}>
        <Typography
          variant="body2"
          sx={{ color: 'grey', textAlign: 'right', marginBottom: '10px' }}
        >
          {new Date().toLocaleString()}
        </Typography>
      </Box>
      <Box
        className="chat-history"
        sx={{ flexGrow: 1, overflowY: 'auto', padding: '10px', backgroundColor: '#424242', borderRadius: '10px', color: 'white' }}
      >
        {chatHistory.map((message, index) => (
          <Box
            key={index}
            className="chat-message"
            sx={{ backgroundColor: '#303030', borderRadius: '10px', margin: '5px 0', padding: '10px', color: 'white' }}
          >
            <Box className="chat-message-text">{message.text}</Box>
            <Box className="chat-message-timestamp" sx={{ fontSize: '0.75rem', color: 'gray', textAlign: 'right' }}>
              {message.timestamp}
            </Box>
          </Box>
        ))}

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
            <CircularProgress color="inherit" size={24} />
          </Box>
        )}

        <form onSubmit={handleSubmit} className="chat-input-form" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <Search sx={{ flexGrow: 1 }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Ask Onmode..."
              inputProps={{ 'aria-label': 'search' }}
              value={inputValue}
              onChange={handleInputChange}
              disabled={loading}
            />
          </Search>
          <button
            type="submit"
            className="send-button app-search-button"
            style={{
              backgroundColor: '#1976d2',
              color: 'white',
              border: 'none',
              padding: '6px 16px',
              cursor: 'pointer',
              fontSize: '0.875rem',
              borderRadius: '4px',
              textTransform: 'uppercase',
              marginLeft: '10px',
            }}
            disabled={loading}
          >
            Go
          </button>
        </form>
      </Box>
    </div>
  );
};

export default ChatOverlay;
